<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" ref="agGrid" pageTitle="Mes articles (PharmaConnect)" @refresh="loadData"
                    :add="true" @cellDoubleClicked="cellDoubleClicked" @plusClicked="plusClicked" />
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            selected: {},
            searchQuery: "",
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Code",
                        field: "code",
                    },
                    {
                        headerName: "Description",
                        field: "description",
                    },
                    {
                        headerName: "Famille",
                        field: "familyDescription",
                    },
                    {
                        headerName: "Sous-Famille",
                        field: "subFamilyDescription",
                    },
                    {
                        headerName: "Prix H.T",
                        field: "priceHT",
                    },
                    {
                        headerName: "Prix T.T.C",
                        field: "priceTTC",
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        plusClicked() {
            this.$router.push({ name: 'supplier-item-sheet', params: { code: "new-item" } });
        },
        async loadData() {
            this.showLoading = true;
            const resp = await this.$http.get("_item/itemsByVendor");
            this.agGrid.rows = resp.data;
            this.showLoading = false;
        },
        cellDoubleClicked(param) {
            this.$router.push({ name: 'supplier-item-sheet', params: { code: param.data.code } });
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>